<template>
  <div>
    <transition name="flixFadeIn">
      <div :key="open">
        <div class="flix-form-group" v-if="!open && !deleted">
          <a href="#" class="flix-btn flix-btn-danger flix-btn-xs" @click.prevent="open = !open">
            <flixIcon id="bin" /> {{ $tc('message.message', 2) }} {{ $t('message.delete') }}
          </a>
        </div>
        <div v-if="open">
          <span class="flix-text-danger">{{ $t('message.allDataWillBeDeleted') }}</span> <a href="#" class="flix-btn flix-btn-xs flix-btn-default" @click.prevent="function () { setDelete() }">{{ $t('message.yes') }}</a> <a class="flix-btn flix-btn-xs flix-btn-default" href="#" @click.prevent="open = false">{{ $t('message.no') }}</a>
        </div>
        <transition name="flixFadeIn">
          <div :key="deleted" v-if="deleted">
            <div class="flix-alert flix-alert-success">{{ $t('message.successfullDeleted') }}</div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    settings: Object,
    callback: Function
  },
  data () {
    return {
      open: false,
      variables: this.$getUserVariables(),
      deleted: false
    }
  },
  methods: {
    setDelete () {
      this.$flix_post({
        url: 'userchat/delete',
        data: {
          user: this.settings.user,
          form: this.settings.form,
          entryID: this.settings.entryID
        },
        callback: function (ret) {
          this.open = false
          this.deleted = false
          this.callback()
        }.bind(this)
      })
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
